import { Hero } from "components/Hero";
import { Link } from "gatsby";
import React from "react";
import Helmet from "react-helmet";

export default function NotFoundPage({ location }) {
  const title = "404 | Plush Oxford";
  const description = "Page not found.";

  return (
    <>
      <Helmet
        htmlAttributes={{ lang: "en-GB" }}
        title={title}
        meta={[
          { name: "description", content: description },
          { property: "og:url", content: location.href },
          { property: "og:title", content: title },
          { property: "og:description", content: description },
          { property: "og:image", content: "" },
          { name: "facebook-domain-verification", content: "" },
        ]}
      />
      <Hero>
        <div className="not_found__hero">
          <h1>Page Not Found</h1>
          <h2>There's nothing here...</h2>
          <p>
            <Link className="animated_link" to="/">
              Back to home
            </Link>
          </p>
        </div>
      </Hero>
    </>
  );
}
